<template>
  <div class="container_box">
    <!--头部筛选区域-->
    <div class="filter_box">
      <Form ref="pageParams" :model="pageParams" :label-width="labelWidth" @submit.native.prevent>
        <Row type="flex" :gutter="gutterItem">
          <Col :xxl="oneItemCol" :xl="oneItemCol" :lg="oneItemCol" :md="oneItemCol">
            <Form-item :label="$t('key1004518')">
              <morePlatforms
                :platformList="platformList"
                :dataSource="true"
                :defaultPlatform="defaultPlatform"
                @selectPlatform="selectPlatform">
              </morePlatforms>
            </Form-item>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol">
            <Form-item :label="$t('key1006279')" prop="code">
              <Input :placeholder="$t('key1006280')" v-model.trim="pageParams.code" clearable></Input>
            </Form-item>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol">
            <Form-item :label="$t('key1006281')" prop="name">
              <Input :placeholder="$t('key1006282')" v-model.trim="pageParams.name" clearable></Input>
            </Form-item>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol">
            <Form-item :label="$t('key1004506')" prop="platformAccountStatus">
              <Select v-model="pageParams.platformAccountStatus" :placeholder="$t('key1000096')">
                <Option v-for="item in authorizationStatusList" :key="item.value" :value="item.value">{{ item.title }}</Option>
              </Select>
            </Form-item>
          </Col>
        </Row>
        <Row type="flex" justify="space-between" :gutter="gutterItem">
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol">
            <Form-item :label="$t('key1006283')">
              <Date-picker
                style="width:100%"
                type="datetimerange"
                @on-clear="resetDate"
                transfer
                @on-change="getDateValue"
                :clearable="true"
                :options="dateOptions"
                format="yyyy-MM-dd HH:mm:ss"
                placement="bottom-end"
                :placeholder="$t('key1000273')"
                :value="expirationTimeArr">
              </Date-picker>
            </Form-item>
          </Col>
          <Col style="width: 200px;">
            <Button @click="reset" icon="md-refresh" class="mr10">{{ $t('key1000095') }}</Button>
            <Button type="primary" @click="search" icon="md-search">{{ $t('key1000274') }}</Button>
          </Col>
        </Row>
      </Form>
    </div>
    <!--功能按钮操作区域-->
    <div class="operation_box">
      <Button type="primary" class="mr15" @click="authorizedOperation(pageParams.platformId)">{{ $t('key1000998') }}</Button>
      <!--YMS-11993需求调整需要暂时屏蔽掉-->
      <!--<Button class="mr15" type="primary" @click="openAuthorizedStore">管理已授权店铺</Button>-->
      <Button class="mr15" type="primary" v-if="pageParams.platformId === 'jushuitan'"
        @click="$refs.inventorySyncRules.getInventorySyncRules()">{{ $t('key1004635') }}</Button>
    </div>
    <!--列表区域-->
    <div class="table_box">
      <Table
        highlight-row
        :loading="tableLoading"
        border
        max-height="550"
        :columns="tableColumn"
        :data="tableData">
      </Table>
      <Page
        v-if="!tableLoading"
        :total="total"
        @on-change="changePage"
        show-total
        :page-size="pageParams.pageSize"
        show-elevator
        :current="pageParams.pageNum"
        show-sizer
        @on-page-size-change="changePageSize"
        placement="top"
        :page-size-opts="pageArray">
      </Page>
    </div>
    <!--管理已授权店铺弹窗-->
    <authorizedStore ref="authorizedStore" @getList="search"></authorizedStore>
    <!--聚水潭平台--库存同步规则弹窗-->
    <inventorySyncRulesModal ref="inventorySyncRules"></inventorySyncRulesModal>
  </div>
</template>

<script>
import { alias47916751af154eb5b47cd5de9d34633d } from '@/customFolder/customVueAlias.js';

import Mixin from '@/components/mixin/common_mixin';
import api from '@/api/';
import morePlatforms from "@/components/common/morePlatforms";
import authorizedStore from "@/components/common/authorizedStore";
import inventorySyncRulesModal from "@/views/supplier/components/thirdPartyPlatform/inventorySyncRulesModal";

export default {
  name: "platformAuthorization",
  mixins: [Mixin],
  data() {
    let v = this;
    return {
      pageParamsStatus: false,
      pageParams: {
        platformId: null,
        code: '',
        name: '',
        invalidStartTime: null,
        invalidEndTime: null,
        platformAccountStatus: 'null',
        pageSize: 15,
        pageNum: 1
      },
      tableColumn: [
        {
          title: alias47916751af154eb5b47cd5de9d34633d.t('key1002809'),
          key: 'platformId',
          align: 'center',
          minWidth: 120,
          render: (h, params) => {
            let platformName = '';
            let platformId = params.row.platformId;
            if (v.platformList.length > 0) {
              v.platformList.map((item) => {
                if (item.platformId === platformId) {
                  platformName = item.platformName;
                }
              })
            }
            return h('span', platformName);
          }
        },
        {
          title: alias47916751af154eb5b47cd5de9d34633d.t('key1000993'),
          key: 'code',
          minWidth: 120,
          align: 'center'
        },
        {
          title: alias47916751af154eb5b47cd5de9d34633d.t('key1006284'),
          key: 'name',
          minWidth: 120,
          align: 'center'
        },
        {
          title: alias47916751af154eb5b47cd5de9d34633d.t('key1004809'),
          key: 'authorizationStatus',
          align: 'center',
          minWidth: 120,
          render: (h, params) => {
            let isShopeeChildAccount = !!(params.row.parentYmsPlatformAccountId && params.row.platformId === 'shopee'); // 是否为shopee子账号
            if (params.row.ymsPlatformAccountTokens) {
              let data = params.row.ymsPlatformAccountTokens;
              if (data) {
                return h('div', data.map(i => {
                  return h('p', {class: 'flex align-items-center flex-wrap'}, [
                    h('span', {
                      domProps: {
                        title: i.authName,
                      }
                    }, i.authName),
                    h('span', {
                      style: {
                        color: i.status === 1 ? 'green' : 'red'
                      },
                      class: 'ml5 mr5',
                    }, i.status === 1 ? alias47916751af154eb5b47cd5de9d34633d.t('key1000995') : alias47916751af154eb5b47cd5de9d34633d.t('key1000996')),
                    (i.authDescription && !isShopeeChildAccount) ? h('Poptip', {
                      props: {
                        'word-wrap': true,
                        trigger: 'hover',
                        content: i.authDescription
                      }
                    }, [h('Icon', {
                      props: {
                        size: 16,
                        type: 'ios-help-circle'
                      }
                    })]) : '',
                    (!isShopeeChildAccount && i.authType.includes('oauth') && v.getPermission('ymsPlatformAccount_applyAuth')) ? h('span', {
                      class: 'goAuth',
                      on: {
                        'click'() {
                          v.editStoreBtn(params.row.platformId, params.row.ymsPlatformAccountId, i.authType, 'toauth');
                        }
                      }
                    }, alias47916751af154eb5b47cd5de9d34633d.t('key1004810')) : ''
                  ]);
                }));
              }
            }
          }
        },
        {
          title: alias47916751af154eb5b47cd5de9d34633d.t('key1006285'),
          key: 'authorizationTime',
          minWidth: 180,
          align: 'center',
          render: (h, params) => {
            return h('div', v.$uDate.dealTime(params.row.authorizationTime, 'fulltime'));
          }
        },
        {
          title: alias47916751af154eb5b47cd5de9d34633d.t('key1004814'),
          key: 'accessTokenInvalidTime',
          minWidth: 180,
          align: 'center',
          render: (h, params) => {
            return h('div', v.$uDate.dealTime(params.row.accessTokenInvalidTime, 'fulltime'));
          }
        },
        {
          title: alias47916751af154eb5b47cd5de9d34633d.t('key1001642'),
          key: 'status',
          align: 'center',
          minWidth: 100,
          render: (h, params) => {
            let status = params.row.status;
            return status === 0 ? h('span', {
              class: 'stopStatus'
            }, alias47916751af154eb5b47cd5de9d34633d.t('key1000400')) : h('span', {
              class: 'openStatus'
            }, alias47916751af154eb5b47cd5de9d34633d.t('key1000399'));
          }
        },
        {
          title: alias47916751af154eb5b47cd5de9d34633d.t('key1006286'),
          key: 'synchronization',
          align: 'center',
          minWidth: 160,
          render: (h, params) => {
            let status = params.row.authorizationStatus;
            let platformId = params.row.platformId;
            let synchronizationTypeList = [];
            let keyList = [];
            let obj = params.row;
            let checkboxList = [];
            for (let key in obj) {
              keyList.push(key);
            }

            // 聚水潭、ERP2.0的库存
            let stockObj = h('Checkbox', {
              props: {
                value: params.row.inventorySync === 1
              },
              style: {
                marginLeft: '10px',
                marginRight: 0
              },
              on: {
                'on-change'(val) {
                  v.tableData.map((ele) => {
                    v.$set(ele, 'inventorySync', 0)
                  })
                  let value = val ? 1 : 0;
                  v.$set(v.tableData[params.index], 'inventorySync', value);
                  v.handerSynchronization('inventorySync', value, params.row.ymsPlatformAccountId);
                }
              }
            }, alias47916751af154eb5b47cd5de9d34633d.t('key1000759'));

            // EERP2.0 同步库存需要选择仓库
            if (v.ymsPlatformAccountWarehouseList.length > 0) {
              v.ymsPlatformAccountWarehouseList.map((ele) => {
                checkboxList.push(h("checkbox-group", {
                      props: {
                        value: v.queryWarehouseByAccountIdList
                      },
                      on: {
                        'on-change': (val) => {
                          v.queryWarehouseByAccountIdList = val;
                        }
                      }
                    },
                    [
                      h("checkbox", {
                        props: {
                          label: ele.warehouseId
                        },
                        style: {
                          display: "flex",
                          margin: '10px 0'
                        },
                      }, ele.warehouseName),
                    ])
                )
              });
            }
            let conent = h('div', {
              slot: 'title',
            }, [
              h('h2', {class: 'mb10 synchronous_warehouse_title'}, alias47916751af154eb5b47cd5de9d34633d.t('key1006287')),
              h('Alert', {
                props: {
                  type: 'warning',
                  showIcon: true
                }
              }, alias47916751af154eb5b47cd5de9d34633d.t('key1006288')),
              h('div', {class: 'synchronous_warehouse_conent'}, checkboxList)
            ]);

            // 1688平台(以后通用的平台)
            v.dataSyncList.map((item) => {
              if (keyList.includes(item.type)) {
                synchronizationTypeList.push(
                  h('Checkbox', {
                    props: {
                      value: params.row[item.type],
                      trueValue: 'Y',
                      falseValue: 'N'
                    },
                    style: {
                      marginLeft: '10px',
                      marginRight: 0
                    },
                    on: {
                      'on-change'(val) {
                        v.tableData.map((ele) => {
                          ele[item.type] = 'N';
                        });
                        v.$set(v.tableData[params.index], item.type, val);
                        setTimeout(() => {
                          v.save1688SyncSettings(params.index);
                        }, 1500);
                      }
                    }
                  }, item.name)
                )
              }
            });
            let synchronizationList = h('div', {class: 'flex align-items-center'}, synchronizationTypeList)

            return status === 1 ? h('div', {
                class: 'flex flex-wrap justify-content-center align-items-center'
              },
              [
                // 聚水潭、ERP2.0 走原来的库存设置（通过inventorySync字段判断）,1688平台（走通用的设置）以后的平台都走这个默认通用设置
                ['jushuitan', 'tongtoolERP2'].includes(platformId) ? stockObj : synchronizationList,
                // 多个仓库库存
                platformId === 'tongtoolERP2' ? h('Poptip', {
                  props: {
                    confirm: true,
                    transfer: true,
                    popperClass: 'synchronous_warehouse_box_style'
                  },
                  on: {
                    'on-ok'() {
                      v.synchronousWarehouseBtn(params.row.ymsPlatformAccountId);
                    }
                  }
                }, [
                  h('Icon', {
                    props: {
                      type: 'md-arrow-dropdown',
                      size: '24'
                    },
                    attrs: {
                      title: alias47916751af154eb5b47cd5de9d34633d.t('key1006289')
                    },
                    style: {
                      cursor: 'pointer'
                    },
                    on: {
                      'click'() {
                        v.getQueryWarehouseByAccountId(params.row.ymsPlatformAccountId)
                      }
                    }
                  }),
                  conent
                ]) : ''
              ]
            ) : '';
          }
        },
        {
          title: alias47916751af154eb5b47cd5de9d34633d.t('key1000283'),
          key: 'options',
          align: 'center',
          fixed: 'right',
          width: 180,
          render: (h, params) => {
            let authorizationStatus = params.row.authorizationStatus;
            let status = params.row.status;
            return h('optsButtonList', {
              props: {
                operationList: [
                  {
                    text: alias47916751af154eb5b47cd5de9d34633d.t('key1004510'),
                    show: authorizationStatus === 1 && v.getPermission('ymsPlatformAccount_applyAuth'),
                    clickFn: () => {
                      v.authorizeBtn(params.row.platformId, params.row.ymsPlatformAccountId);
                    }
                  },
                  {
                    text: alias47916751af154eb5b47cd5de9d34633d.t('key1006290'),
                    show: authorizationStatus !== 1 && v.getPermission('ymsPlatformAccount_applyAuth'),
                    clickFn: () => {
                      v.authorizeBtn(params.row.platformId, params.row.ymsPlatformAccountId);
                    }
                  },
                  {
                    text: alias47916751af154eb5b47cd5de9d34633d.t('key1000399'),
                    show: status === 0 && v.getPermission('ymsPlatformAccount_enable'),
                    clickFn: () => {
                      v.enableOrDisablePlatform('enable', params.row.ymsPlatformAccountId);
                    }
                  },
                  {
                    text: alias47916751af154eb5b47cd5de9d34633d.t('key1000400'),
                    show: status === 1 && v.getPermission('ymsPlatformAccount_disable'),
                    clickFn: () => {
                      v.enableOrDisablePlatform('disable', params.row.ymsPlatformAccountId);
                    }
                  }
                ]
              },
              on: {
                setWidth(value) {
                  if (value) {
                    v.optionBtnWidth.push(value);
                    params.column.width = Math.max(...v.optionBtnWidth);
                    v.$forceUpdate();
                  }
                }
              }
            });
          }
        }
      ],
      tableData: [],
      total: 0,
      platformList: [],
      authorizationStatusList: [
        {value: 'null', title: alias47916751af154eb5b47cd5de9d34633d.t('key1000096')},
        {value: 1, title: alias47916751af154eb5b47cd5de9d34633d.t('key1004512')},
        {value: 0, title: alias47916751af154eb5b47cd5de9d34633d.t('key1004513')}
      ],
      expirationTimeArr: [],
      dataSyncList: [
        {type: 'alibabaProductSync', name: alias47916751af154eb5b47cd5de9d34633d.t('key1006291'), value: 'N'},
        {type: 'alibabaInventorySync', name: alias47916751af154eb5b47cd5de9d34633d.t('key1000759'), value: 'N'}
      ],
      ymsPlatformAccountWarehouseList: [],
      queryWarehouseByAccountIdList: [],
      defaultPlatform: '',
    }
  },
  created() {
    this.initFun();
  },
  methods: {
    // 初始化接口数据
    initFun() {
      (async () => {
        await this.initWebSocket();
        await this.getYmsThirdPlatformData();
        await this.getSynchronousWarehouseList();
      })();
    },
    // 初始化 Websocket
    initWebSocket() {
      let v = this;
      v.$webSocket.socket.onmessage = function (e) {
        v.$webSocket.heartReset().heartStart();
        let data = '';
        if (e) {
          try {
            data = JSON.parse(e.data);
          } catch (e) {
            data = null;
          }
          if (data) {
            if (data.type === 'auth') {
              v.$Message.success(alias47916751af154eb5b47cd5de9d34633d.t('key1004820'));
            } else if (data.type === 'save') {
              v.$Message.success(alias47916751af154eb5b47cd5de9d34633d.t('key1004821'));
            }
            v.pageParamsStatus = true;
          }
        }
      };
    },
    // 编辑店铺
    editStoreBtn(platformId, ymsPlatformAccountId, authType, action) {
      this.authorizedOperation(platformId, ymsPlatformAccountId, authType, action);
    },
    // 获取第三方平台数据
    getYmsThirdPlatformData() {
      let v = this;
      v.axios.get(api.get_ymsThirdPlatform_query).then(response => {
        if (response.data.code === 0) {
          let data = response.data.datas;
          if (data && data.length > 0) {
            data.map((item) => {
              item.name = item.platformName;
            });
            v.platformList = data;
            v.pageParams.platformId = v.platformList[0].platformId || null;
            v.defaultPlatform = v.platformList[0].platformId;
            v.getList();
          }
        }
      });
    },
    // 查询按钮
    search() {
      this.pageParams.pageNum = 1;
      this.pageParamsStatus = true;
    },
    // 重置按钮
    reset() {
      let v = this;
      v.$refs['pageParams'].resetFields();
      v.pageParams.platformId = v.defaultPlatform;
      v.expirationTimeArr = [];
      v.resetDate();
      v.getList();
    },
    // 获取列表数据
    getList() {
      let v = this;
      v.tableData = [];
      v.total = 0;
      if (v.getPermission('ymsPlatformAccount_query')) {
        v.tableLoading = true;
        let query = JSON.parse(JSON.stringify(v.pageParams));
        if (v.pageParams.platformAccountStatus === 'null') {
          query.platformAccountStatus = null;
        }
        v.axios.post(api.post_ymsPlatformAccount_query, query).then(response => {
          if (response.data.code === 0) {
            let data = response.data.datas;
            let newList = data.list || [];
            if (newList.length > 0) {
              newList.map((item) => {
                item['authorizationTime'] = null;
                item['accessTokenInvalidTime'] = null;
                item['authorizationStatus'] = 0;
                if (item.ymsPlatformAccountTokens && item.ymsPlatformAccountTokens.length > 0) {
                  // 1688平台
                  if (query.platformId === 'alibaba') {
                    let list = item.ymsPlatformAccountTokens.filter((ele) => {
                      return ele.authType === 'oauth1'
                    });
                    if (list.length > 0) {
                      item['authorizationStatus'] = list[0].status;
                      item['authorizationTime'] = list[0].updatedTime;
                      item['accessTokenInvalidTime'] = list[0].refreshTokenInvalidTime;
                    }
                  }
                  // 其他平台
                  else {
                    item['authorizationStatus'] = item.ymsPlatformAccountTokens[0].status;
                    item['authorizationTime'] = item.ymsPlatformAccountTokens[0].updatedTime;
                    item['accessTokenInvalidTime'] = item.ymsPlatformAccountTokens[0].accessTokenInvalidTime;
                  }
                }
                v.dataSyncList.map((ele) => {
                  item[ele.type] = ele.value;
                });
              });
              v.getSyncSettings(newList);
            } else {
              v.tableLoading = false;
            }
            v.total = data.total;
          } else {
            v.tableLoading = false;
          }
        });
      } else {
        v.gotoError();
      }
    },
    // 获取对应平台下的数据同步的设置
    getSyncSettings(list) {
      let v = this;
      let talg = ['alibaba'].includes(v.pageParams.platformId);
      if (talg) {
        let query = {
          typeList: [v.pageParams.platformId]
        };
        v.axios.post(api.post_erpCommonSettingParam_queryPlatformSyncSetting, query).then(response => {
          if (response.data.code === 0) {
            let data = response.data.datas;
            if (data && data.length > 0) {
              list.map((item, index) => {
                data.map((ele) => {
                  if (item.thirdAuthAccountId === ele.businessId) {
                    item[ele.paramKey] = ele.paramValue
                  }
                })
              });
            }
            v.tableData = list;
            v.$nextTick(() => {
              v.tableLoading = false;
            });
            v.$forceUpdate();
          } else {
            v.tableLoading = false;
          }
        });
      } else {
        v.tableData = list;
        v.$nextTick(() => {
          v.tableLoading = false;
        });
      }
    },
    // 重置到期时间
    resetDate() {
      this.pageParams.invalidStartTime = null;
      this.pageParams.invalidEndTime = null;
    },
    // 获取到期时间
    getDateValue(value) {
      let v = this;
      if (value.length === 0) {
        v.$Message.error(alias47916751af154eb5b47cd5de9d34633d.t('key1000289'));
        v.resetDate();
      } else {
        if (!value[0]) {
          v.pageParams.invalidStartTime = null;
          v.pageParams.invalidEndTime = null;
        } else {
          let timeList = v.defaultTimePeriod(value);
          v.expirationTimeArr = v.defaultTimePeriod(value, false);
          v.pageParams.invalidStartTime = v.$uDate.getUniversalTime(new Date(timeList[0]).getTime(), 'fulltime');
          v.pageParams.invalidEndTime = v.$uDate.getUniversalTime(new Date(timeList[1]).getTime(), 'fulltime');
        }
      }
    },
    // 启用、停用平台
    enableOrDisablePlatform(type, ymsPlatformAccountId) {
      let url = '';
      let text = '';
      if (type === 'disable') {
        url = api.put_ymsPlatformAccount_disable;
        text = alias47916751af154eb5b47cd5de9d34633d.t('key1001949');
      } else {
        url = api.put_ymsPlatformAccount_enable;
        text = alias47916751af154eb5b47cd5de9d34633d.t('key1002132');
      }
      this.axios.put(url, ymsPlatformAccountId, {loading: true, loadingText: alias47916751af154eb5b47cd5de9d34633d.t('key1000213')}).then(response => {
        if (response.data.code === 0) {
          this.pageParamsStatus = true;
          this.$Message.success(text);
        }
      });
    },
    // 授权、重新授权
    authorizeBtn(platformId, ymsPlatformAccountId, authType, action) {
      this.authorizedOperation(platformId, ymsPlatformAccountId, authType, action);
    },
    // 授权、重新授权需要调申请授权接口重定向到三方授权系统进行操作
    authorizedOperation(platformId, ymsPlatformAccountId = null, authType = '', action = 'edit') {
      let v = this;
      let query = {
        "action": action, // 行动(toauth(返回授权地址)、edit(返回表单地址))
        "ymsPlatformAccountId": ymsPlatformAccountId, // 授权账号TOKEN流水号
        "currentAuthType": authType,
        "platformId": platformId
      }
      if (['alibaba'].includes(platformId)) {
        query.authTypes = ['oauth2'];
      }
      v.axios.post(api.post_ymsPlatformAccount_applyAuth, query, {loading: true, loadingText: alias47916751af154eb5b47cd5de9d34633d.t('key1000213')}).then(res => {
        if (res.data.code === 0) {
          let data = res.data.datas;
          if (data) {
            window.open(data.redirectUrl, '_blank');
          } else {
            v.$Message.error(alias47916751af154eb5b47cd5de9d34633d.t('key1004515'));
            return false;
          }
        }
      });
    },
    // 处理数据同步 --（库存同步）
    handerSynchronization(type, value, ymsPlatformAccountId) {
      let v = this;
      let query = {
        [type]: value,
        ymsPlatformAccountId: ymsPlatformAccountId
      };
      v.axios.put(api.put_ymsPlatformAccount_type + `${type}`, query, {loading: true, loadingText: alias47916751af154eb5b47cd5de9d34633d.t('key1000213')}).then((res) => {
        if (res.data.code === 0) {
          v.$Message.success(alias47916751af154eb5b47cd5de9d34633d.t('key1000085'));
          v.pageParamsStatus = true;
        }
      });
    },
    // 获取同步库存到仓库的基础数据
    getSynchronousWarehouseList() {
      let v = this;
      v.axios.get(api.get_ymsPlatformAccountWarehouse_warehouseQuery).then((res) => {
        if (res.data.code === 0) {
          let data = res.data.datas;
          v.ymsPlatformAccountWarehouseList = data || [];
        }
      });
    },
    // 获取已经设置同步仓库的id
    getQueryWarehouseByAccountId(ymsPlatformAccountId) {
      let v = this;
      v.queryWarehouseByAccountIdList = [];
      v.axios.get(api.get_ymsPlatformAccountWarehouse_queryWarehouseByAccountId + `${ymsPlatformAccountId}`).then((res) => {
        if (res.data.code === 0) {
          let data = res.data.datas;
          if (data && data.length > 0) {
            v.queryWarehouseByAccountIdList = data.map((item) => {
              return item.platformWarehouseId
            })
          }
        }
      });
    },
    // 同步库存到对应仓库的确定按钮
    synchronousWarehouseBtn(ymsPlatformAccountId) {
      let v = this;
      let queryList = [];
      if (v.queryWarehouseByAccountIdList.length > 0) {
        v.ymsPlatformAccountWarehouseList.map((item) => {
          v.queryWarehouseByAccountIdList.map((id) => {
            if (item.warehouseId === id) {
              queryList.push({
                platformWarehouseId: item.warehouseId,
                platformWarehouseName: item.warehouseName,
                ymsPlatformAccountId: ymsPlatformAccountId
              });
            }
          })
        });
        let newList = v.uniqueFunc(queryList, 'platformWarehouseId');
        v.axios.post(api.post_ymsPlatformAccountWarehouse_insertWarehouse, newList, {loading: true, loadingText: alias47916751af154eb5b47cd5de9d34633d.t('key1003824')}).then((res) => {
          if (res.data.code === 0) {
            v.$Message.success(alias47916751af154eb5b47cd5de9d34633d.t('key1000085'));
            v.pageParamsStatus = true;
          }
        });
      } else {
        v.$Message.warning(alias47916751af154eb5b47cd5de9d34633d.t('key1006292'));
        return false;
      }
    },
    // 选取平台
    selectPlatform(platformId) {
      this.pageParams.platformId = platformId;
      this.pageParamsStatus = true;
    },
    // 管理已授权店铺
    openAuthorizedStore() {
      this.$refs['authorizedStore'].getAuthorizedStore({
        platformId: this.pageParams.platformId
      });
    },
    // 1688平台数据同步
    save1688SyncSettings(currentIndex) {
      let v = this;
      let paramBoList = [];
      v.tableData.map((item, index) => {
        if (index === currentIndex) {
          v.dataSyncList.map((ele) => {
            paramBoList.push({
              businessId: item.thirdAuthAccountId,
              paramKey: ele.type,
              paramValue: item[ele.type],
            })
          })
        }
      })
      let query = {
        paramBoList: paramBoList
      };
      v.axios.post(api.post_erpCommonSettingParam_saveAlibabaSyncSetting, query, {loading: true, loadingText: alias47916751af154eb5b47cd5de9d34633d.t('key1000213')}).then((res) => {
        if (res.data.code === 0) {
          v.$Message.success(alias47916751af154eb5b47cd5de9d34633d.t('key1000085'));
          v.pageParamsStatus = true;
        }
      });
    },
  },
  components: {
    morePlatforms,
    authorizedStore,
    inventorySyncRulesModal
  },
  watch: {
    pageParamsStatus(value) {
      let v = this;
      if (value) {
        v.getList();
        v.pageParamsStatus = false;
      }
    }
  }
}
</script>

<style lang="less">
.synchronous_warehouse_box_style {
  width: 300px;

  .ivu-poptip-body {
    padding: 0;
    max-height: 300px;
    overflow-y: auto;

    .ivu-icon-ios-help-circle {
      display: none;
    }

    .ivu-poptip-body-message {
      padding: 0;
    }

    .synchronous_warehouse_title {
      padding: 8px 0 8px 12px;
      border-bottom: 1px solid #EEEEEE;
      font-size: 13px;
      color: #333;
    }

    .synchronous_warehouse_conent {
      padding: 0 0 0 18px;
    }

    .ivu-checkbox {
      margin-right: 5px;
    }

    .ivu-alert-warning {
      font-size: 12px;
      color: #666;
      border: none;
      background-color: transparent;
    }
  }

  .ivu-poptip-footer {
    border-top: 1px solid #EEEEEE;
    padding: 12px 15px 12px 0;
  }
}

.goAuth {
  color: #2D8CF0;
  cursor: pointer;
  white-space: nowrap;
}

.goAuth:hover {
  text-decoration: underline;
}
</style>
